import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import CHealthHeroSection from '../sections/caseStudies/cHealth/CHealthHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import CHealthSection1 from '../sections/caseStudies/cHealth/CHealthSection1';
import CHealthSection2 from '../sections/caseStudies/cHealth/CHealthSection2';
import CHealthSection3 from '../sections/caseStudies/cHealth/CHealthSection3';
import CHealthSection4 from '../sections/caseStudies/cHealth/CHealthSection4';


const Chealth = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudyCHealthPageACF;
  console.log(mainData);
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');
  return (
    <Layout headerClass="white-header">
      <Seo title="CHealth" />
      <div className={"chealth-case-study page-wrapper " + bgTransition} id="chealth-casestudy-page-wrapper" ref={sectionRef}>
        <CHealthHeroSection 
            preTitle={parse(mainData.chealthHeroSectionPretitle)}
            mainTitle={parse(mainData.chealthHeroSectionTitle)}
            image1x={
              {
                sourceUrl: mainData.chealthHeroSectionImage1x.sourceUrl,
                altText: mainData.chealthHeroSectionImage1x.altText,
                width: mainData.chealthHeroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.chealthHeroSectionImage2x.sourceUrl,
                altText: mainData.chealthHeroSectionImage2x.altText,
                width: mainData.chealthHeroSectionImage2x.width,
              }
            }
            mobileImage={
              {
                sourceUrl: mainData.chealthHeroSectionMobileImage.sourceUrl,
                altText: mainData.chealthHeroSectionMobileImage.altText,
                width: mainData.chealthHeroSectionMobileImage.width,
              }
            }
        />
         <ChallengesSection 
            companyInfoList={mainData.chealthChallengeSectionCompanyInfoList}
            challengesList={mainData.chealthChallengeSectionChallangesList}
        />
        <CHealthSection1 
            mainTitle={parse(mainData.chealthSection1Title)}
            mainDescription={parse(mainData.chealthSection1Description)}
            image11x={
              {
                sourceUrl: mainData.chealthSection1Image11x.sourceUrl,
                altText: mainData.chealthSection1Image11x.altText,
                width: mainData.chealthSection1Image11x.width,
              }
            }
            image12x={
              {
                sourceUrl: mainData.chealthSection1Image12x.sourceUrl,
                altText: mainData.chealthSection1Image12x.altText,
                width: mainData.chealthSection1Image12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.chealthSection1Image21x.sourceUrl,
                altText: mainData.chealthSection1Image21x.altText,
                width: mainData.chealthSection1Image21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.chealthSection1Image22x.sourceUrl,
                altText: mainData.chealthSection1Image22x.altText,
                width: mainData.chealthSection1Image22x.width,
              }
            }
            image31x={
              {
                sourceUrl: mainData.chealthSection1Image31x.sourceUrl,
                altText: mainData.chealthSection1Image31x.altText,
                width: mainData.chealthSection1Image31x.width,
              }
            }
            image32x={
              {
                sourceUrl: mainData.chealthSection1Image32x.sourceUrl,
                altText: mainData.chealthSection1Image32x.altText,
                width: mainData.chealthSection1Image32x.width,
              }
            }
        />
        <CHealthSection2 
            mainTitle={parse(mainData.chealthSection2Title)}
            mainDescription={parse(mainData.chealthSection2Description)}
            mainTitle2={parse(mainData.chealthSection2Title2)}
            image11x={
              {
                sourceUrl: mainData.chealthSection2Image11x.sourceUrl,
                altText: mainData.chealthSection2Image11x.altText,
                width: mainData.chealthSection2Image11x.width,
              }
            }
            image12x={
              {
                sourceUrl: mainData.chealthSection2Image12x.sourceUrl,
                altText: mainData.chealthSection2Image12x.altText,
                width: mainData.chealthSection2Image12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.chealthSection2Image21x.sourceUrl,
                altText: mainData.chealthSection2Image21x.altText,
                width: mainData.chealthSection2Image21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.chealthSection2Image22x.sourceUrl,
                altText: mainData.chealthSection2Image22x.altText,
                width: mainData.chealthSection2Image22x.width,
              }
            }
            image31x={
              {
                sourceUrl: mainData.chealthSection2Image31x.sourceUrl,
                altText: mainData.chealthSection2Image31x.altText,
                width: mainData.chealthSection2Image31x.width,
              }
            }
            image32x={
              {
                sourceUrl: mainData.chealthSection2Image32x.sourceUrl,
                altText: mainData.chealthSection2Image32x.altText,
                width: mainData.chealthSection2Image32x.width,
              }
            }
        />
        <CHealthSection3 
            mainTitle={parse(mainData.chealthSection3Title)}
            mainDescription={parse(mainData.chealthSection3Description)}
            imageslist={mainData.chealthSection3ImageList}
            imageslist2={mainData.chealthSection3ImageList2}
        />
        <CHealthSection4 
            mainTitle={parse(mainData.chealthSection4Title)}
            mainDescription={parse(mainData.chealthSection4Description)}
            image11x={
              {
                sourceUrl: mainData.chealthSection4Image11x.sourceUrl,
                altText: mainData.chealthSection4Image11x.altText,
                width: mainData.chealthSection4Image11x.width,
              }
            }
            image12x={
              {
                sourceUrl: mainData.chealthSection4Image12x.sourceUrl,
                altText: mainData.chealthSection4Image12x.altText,
                width: mainData.chealthSection4Image12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.chealthSection4Image21x.sourceUrl,
                altText: mainData.chealthSection4Image21x.altText,
                width: mainData.chealthSection4Image21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.chealthSection4Image22x.sourceUrl,
                altText: mainData.chealthSection4Image22x.altText,
                width: mainData.chealthSection4Image22x.width,
              }
            }
            image31x={
              {
                sourceUrl: mainData.chealthSection4Image31x.sourceUrl,
                altText: mainData.chealthSection4Image31x.altText,
                width: mainData.chealthSection4Image31x.width,
              }
            }
            image32x={
              {
                sourceUrl: mainData.chealthSection4Image32x.sourceUrl,
                altText: mainData.chealthSection4Image32x.altText,
                width: mainData.chealthSection4Image32x.width,
              }
            }
        />
      </div>
    </Layout>
  )
}



export const CHealthQuery = graphql`

{
  allWpPage(filter: {slug: {eq: "chealth"}}) {
      nodes {
        caseStudyCHealthPageACF {
            chealthHeroSectionPretitle
            chealthHeroSectionTitle
            chealthHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            chealthHeroSectionImage2x {
              altText
              sourceUrl
              width
            }
            chealthHeroSectionMobileImage {
              altText
              sourceUrl
              width
            }
            chealthChallengeSectionCompanyInfoList {
              title
              description
            }
            chealthChallengeSectionChallangesList {
              title
              description
            }

            chealthSection1Title
            chealthSection1Description
            chealthSection1Image11x {
              altText
              sourceUrl
              width
            }
            chealthSection1Image12x {
              altText
              sourceUrl
              width
            }
            chealthSection1Image21x {
              altText
              sourceUrl
              width
            }
            chealthSection1Image22x {
              altText
              sourceUrl
              width
            }
            chealthSection1Image31x {
              altText
              sourceUrl
              width
            }
            chealthSection1Image32x {
              altText
              sourceUrl
              width
            }

            chealthSection2Title
            chealthSection2Description
            chealthSection2Title2
            chealthSection2Image11x {
              altText
              sourceUrl
              width
            }
            chealthSection2Image12x {
              altText
              sourceUrl
              width
            }
            chealthSection2Image21x {
              altText
              sourceUrl
              width
            }
            chealthSection2Image22x {
              altText
              sourceUrl
              width
            }
            chealthSection2Image31x {
              altText
              sourceUrl
              width
            }
            chealthSection2Image32x {
              altText
              sourceUrl
              width
            }

            chealthSection3Title
            chealthSection3Description
            chealthSection3ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            chealthSection3ImageList2 {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }

            chealthSection4Title
            chealthSection4Description
            chealthSection4Image11x {
              altText
              sourceUrl
              width
            }
            chealthSection4Image12x {
              altText
              sourceUrl
              width
            }
            chealthSection4Image21x {
              altText
              sourceUrl
              width
            }
            chealthSection4Image22x {
              altText
              sourceUrl
              width
            }
            chealthSection4Image31x {
              altText
              sourceUrl
              width
            }
            chealthSection4Image32x {
              altText
              sourceUrl
              width
            }
        }
      }
    }
}

`

export default Chealth